import { CountryCodes } from './api';

export const getTranslationKey = (id: string) => [`countries.${id}.name`, id];

export const findCountryCodeFromDomain = (
    countryCodes: CountryCodes,
    domain: string
) =>
    Object.keys(countryCodes).find(value =>
        countryCodes[parseInt(value, 10)].match(new RegExp(domain, 'ig'))
    );

export const mapDomainStringToQueryString = (
    countryCodes: CountryCodes,
    countriesQuery: string
) =>
    countriesQuery
        .split(',')
        .map(domain => findCountryCodeFromDomain(countryCodes, domain) ?? -1)
        .filter(code => code > -1)
        .join(',');

export default findCountryCodeFromDomain;
