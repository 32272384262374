import './styles.css';

import { DeliveryTruck32 } from '@mercell/mercell-ui';
import * as React from 'react';

const SplashLoading: React.FunctionComponent<{}> = ({ children }) => {
    return (
        <div role="status" className="appear flex items-center flex-wrap">
            <div className="flex justify-center flex-wrap w-full">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50vw"
                    height="50vw"
                    aria-hidden="true"
                    preserveAspectRatio=""
                    viewBox="0 0 32 32"
                >
                    <defs />
                    <path
                        fill="none"
                        stroke="#000"
                        strokeWidth=".35"
                        d="M21 5l1 3z"
                    />
                    <path d="M29 29H2v1h28V8h-1c0 15 .05-1.4 0 21zM15.13 14.6h.01l.01.02h.01l.01.01h-.01l-.01-.02h-.01z" />
                    <path d="M18.55 7.86a5.14 5.14 0 017.17 1.21 5.14 5.14 0 01-1.58 7.09 5.26 5.26 0 01-5.5.3c.97.06 2.06-.3 3.21-1.07 1.86-1.25 2.58-2.86 1.3-4.76-1.05-1.58-2.65-1.66-4.27-.57l-.36.25L21 14c-.91.61-3.15 1.28-4.35-.51-.28-.4-.4-.86-.5-1.2a5.32 5.32 0 012.41-4.43z" />
                    <path
                        fill="none"
                        stroke="#000"
                        strokeWidth=".35"
                        d="M24.5 27.5H29V29h-4.5zM20 27.5h4.5V29H20zM15.5 27.5H20V29h-4.5zM17.75 26h4.5v1.5h-4.5zM22.25 26h4.5v1.5h-4.5zM20 24.5h4.5V26H20zM24.5 24.5H29V26h-4.5zM23 23h4.5v1.5H23zM24.5 21.5H29V23h-4.5z"
                    />
                    <path stroke="#000" strokeWidth=".3" d="M6 5.57h3V29H6z" />
                    <path stroke="#000" strokeWidth=".27" d="M1 3h21v2H1z" />
                    <path
                        fill="none"
                        stroke="#000"
                        strokeWidth=".4"
                        d="M21 5l-1 4z"
                    />
                    <path
                        stroke="#000"
                        strokeWidth=".3"
                        d="M1 2h2v4H1zM5.99.99H9v1.4H5.99z"
                    />
                    <path
                        fill="none"
                        stroke="#000"
                        strokeWidth=".3"
                        d="M3 3l3-2h3l12 2"
                    />
                </svg>
                <div className="block w-full text-center">{children}</div>
            </div>
            <div
                className="truck"
                style={{ height: '12.5vw', width: '12.5vw' }}
            >
                <DeliveryTruck32 className="w-full h-full" />
            </div>
        </div>
    );
};

export default SplashLoading;
