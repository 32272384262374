import {} from '@mercell/mercell-ui';
import * as React from 'react';

import { useFeatureEnabled } from '../../FeatureToggleContext';

const BetaBanner: React.FunctionComponent<{}> = () => {
    const isEnabled = useFeatureEnabled('beta-banner-enabled');
    if (!isEnabled) return null;
    return <div className="beta-badge">Beta</div>;
};

export default BetaBanner;
