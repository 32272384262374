import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { getAppDomain } from './utils';

const languageSetting = window.localStorage.getItem('i18nextLng');
if (!languageSetting) {
    const appDomain = getAppDomain();
    if (['dk', 'no'].some(domain => appDomain === domain))
        window.localStorage.setItem('i18nextLng', appDomain);
}

i18n
    // load translation using xhr -> see /public/locales
    // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(HttpApi)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to the react-i18next components.
    // Alternative use the I18nextProvider: https://react.i18next.com/components/i18nextprovider
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'en',

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            format: (value, format, lng) => {
                if (!value) return value;
                if (format === 'lowercase') return value.toLowerCase();
                return value;
            },
        },
        react: {
            useSuspense: true,
        },
    });

export default i18n;
