export const getTranslationKey = (id: string) => [
    `fields.category.values.${id}`,
    id.split('.').pop() ?? id,
];

export const filterSubCategories = (id: string) => !!getMainSubCategory(id)[1];

export const getParentCategory = (id: string) => getMainSubCategory(id)[0];

export const includeParentCategories = (categories: string[]) =>
    Array.from(
        new Set(
            categories
                .filter(filterSubCategories)
                .flatMap(category => [getParentCategory(category), category])
        )
    );

export const excludeSpecifiedCategories = (categoryQueryString: string) => {
    const categories = categoryQueryString.split(',');
    const mainCategories = categories.filter(filterMainCategories);
    const subCategories = categories.filter(filterSubCategories);
    return [
        ...subCategories,
        ...mainCategories.filter(main =>
            subCategories.every(sub => !sub.match(main))
        ),
    ].join(',');
};

const getMainSubCategory = (id: string): [string, string | undefined] =>
    id.split('_') as [string, string | undefined];

const filterMainCategories = (id: string) => !getMainSubCategory(id)[1];
