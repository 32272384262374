import * as React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';

import ChangePassword from './ChangePassword';
import ForgottenPassword from './ForgottenPassword';

const PasswordReset: React.FunctionComponent<{}> = () => {
    const match = useRouteMatch();
    return (
        <>
            <Route path={`${match.url}/change`}>
                <ChangePassword />
            </Route>
            <Route path={`${match.url}/`} exact>
                <ForgottenPassword />
            </Route>
        </>
    );
};

export default PasswordReset;
