import './styles.css';

import { Add24, Search24 } from '@mercell/mercell-ui';
import cx from 'classnames';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useLocation } from 'react-router-dom';

import { useAuthContext } from '../../domain/user/AuthContext';
import ProfileDropdown from './ProfileDropdown';

const AppMenu: React.FunctionComponent<{}> = () => {
    const { t } = useTranslation();
    const { isLoggedIn } = useAuthContext();
    const { pathname } = useLocation();
    return (
        <nav className="fixed bottom-0 left-0 flex items-center bg-white w-full border-t border-grey-dark flex justify-evenly sm:relative sm:align-items-center sm:w-fit-content sm:border-0 z-40 sm:h-full">
            {!isLoggedIn &&
                ['/signup', '/login'].every(path => pathname !== path) && (
                    <>
                        <Link
                            to="/login"
                            className={cx('nav-button btn tertiary')}
                        >
                            {t('log-in')}
                        </Link>
                    </>
                )}
            {isLoggedIn && (
                <>
                    <NavLink
                        to="/browse"
                        className={cx('nav-link w-1/3')}
                        activeClassName="active active-underline"
                    >
                        <Search24 />
                        {t('nav.search')}
                    </NavLink>
                    <ProfileDropdown className="w-1/3" />
                </>
            )}
        </nav>
    );
};

export default AppMenu;
