import './app.css';

import { AppLayout, MercellMarketplace, Search24 } from '@mercell/mercell-ui';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Link,
    NavLink,
    Redirect,
    Route,
    Switch,
    useLocation,
} from 'react-router-dom';

import ApplicationStatusMessage from './components/ApplicationStatusMessage';
import AppMenu from './components/AppMenu';
import BetaBanner from './components/BetaBanner';
import CookieWarning from './components/CookieWarning';
import SplashLoading from './components/SplashLoading';
import { useAuthContext } from './domain/user/AuthContext';
import { hasUserInfo } from './domain/user/authorization-rules';
import { AuthorizedRoute } from './domain/user/components/AutorizationRoute';
import { useFeatureEnabled } from './FeatureToggleContext';
import PasswordReset from './pages/ForgottenPassword';
import Home from './pages/Home';
import { getAppDomain } from './utils';

const Account = React.lazy(() => import('./pages/Account'));
const AdDetails = React.lazy(() => import('./pages/AdDetails'));
const Browse = React.lazy(() => import('./pages/Browse'));
const ConfirmEmail = React.lazy(() => import('./pages/ConfirmEmail'));
const Login = React.lazy(() => import('./domain/user/pages/Login'));
const ManageAd = React.lazy(() =>
    import('./domain/advertisment/pages/ManageAd')
);
const Privacy = React.lazy(() => import('./pages/Privacy'));
const Signup = React.lazy(() => import('./domain/user/pages/Signup'));
const UserPages = React.lazy(() => import('./domain/user/pages/index'));
const LanguageSelection = React.lazy(() =>
    import('./domain/user/pages/LanguageSelection/index')
);

function App() {
    const { t } = useTranslation();
    const { isLoggedIn } = useAuthContext();
    const { pathname } = useLocation();
    const isLandingPage = pathname === '/';

    const pageEnabled = useFeatureEnabled(`domain-enabled-${getAppDomain()}`);
    if (!pageEnabled)
        return (
            <SplashLoading>
                <h1>
                    Page is currently under construction and will be available
                    soon
                </h1>
            </SplashLoading>
        );
    return (
        <>
            <CookieWarning />
            <AppLayout className="pb-16 sm:pb-0">
                <header className="bg-white flex items-center flex-wrap sm:justify-between px-4 sm:px-16 relative sm:h-full">
                    <Link
                        to="/"
                        className="flex items-center text-black text-xl py-2"
                    >
                        <MercellMarketplace
                            className="w-10 h-10 sm:w-12 sm:h-12 mr-4"
                            viewBox="0 0 32 32"
                        />
                        <span>
                            {window.location.origin.match(/agores/gi)
                                ? 'Agores'
                                : 'Findhinanden'}
                        </span>
                    </Link>
                    <BetaBanner />
                    <AppMenu />
                    {!isLoggedIn && (
                        <NavLink
                            to="/browse"
                            className={cx(
                                'btn-icon flex justify-center h-full text-black vertical absolute right-0  sm:hidden'
                            )}
                            activeClassName="active active-underline"
                        >
                            <Search24 />
                            {t('nav.search')}
                        </NavLink>
                    )}
                </header>
                <div
                    role="main"
                    className={cx('h-full w-full flex flex-col', {
                        'px-2 sm:px-20 py-4': !isLandingPage,
                    })}
                >
                    <Switch>
                        <Route path="/login">
                            <Login />
                        </Route>
                        <Route path="/browse">
                            <Browse />
                        </Route>
                        <Route path="/privacy">
                            <Privacy />
                        </Route>
                        <Route path="/advertisment/:id">
                            <AdDetails />
                        </Route>
                        <Route path="/ConfirmEmail/:token">
                            <ConfirmEmail />
                        </Route>
                        <Route path="/account/:id">
                            <Account />
                        </Route>
                        <AuthorizedRoute path="/user">
                            <UserPages />
                        </AuthorizedRoute>
                        <AuthorizedRoute
                            path="/manage-ad/:id?"
                            rules={[
                                {
                                    redirect:
                                        '/user?required=true&redirect=/manage-ad',
                                    validator: hasUserInfo,
                                },
                            ]}
                        >
                            <ManageAd />
                        </AuthorizedRoute>
                        <Route path="/password-reset">
                            <PasswordReset />
                        </Route>
                        <Route path="/language">
                            <LanguageSelection />
                        </Route>
                        <Route exact path="/">
                            <Home />
                        </Route>
                        <Route path="/">
                            <Redirect to="/" />
                        </Route>
                    </Switch>
                </div>
                <ApplicationStatusMessage />
                <footer className="bg-grey-background p-4 self-end border-t-2 border-grey-medium">
                    <div className="flex justify-evenly w-full items-center mb-4 flex-wrap">
                        <h6 className="w-full text-center sm:w-auto">
                            A platform by the Mercell group
                        </h6>
                        <img
                            style={{ maxWidth: 100 }}
                            className="align-middle mr-4 my-4 sm:mr-0 sm:my-0"
                            alt="Mercell logo"
                            src="/img/Mercell-logo-4f.png"
                        />
                        <img
                            style={{ maxWidth: 100 }}
                            className="align-middle mr-4 my-4 sm:mr-0 sm:my-0"
                            alt="EU supply logo"
                            src="/img/EU-Supply_Logo.png"
                        />
                        <img
                            style={{ maxWidth: 100 }}
                            className="align-middle"
                            alt="Innovasion logo"
                            src="/img/innovasion-color.png"
                        />
                        <img
                            style={{ maxWidth: 172 }}
                            className="align-middle"
                            alt="Udbugsvagten logo"
                            src="/img/UBV_logo_sort_cropped.png"
                        />
                    </div>

                    <div className="w-full flex justify-center">
                        <span>© 2020</span>
                        &nbsp;-&nbsp;
                        <a href={t('footer.mercell.link')}>
                            {t('footer.mercell.name')}
                        </a>
                        &nbsp;-&nbsp;
                        <Link to="/privacy">{t('footer.privacy.label')}</Link>
                    </div>
                    <Link className="w-full text-center block" to="/language">
                        {t('pages.language.title')}
                    </Link>
                </footer>
            </AppLayout>
        </>
    );
}

export default App;
