import * as React from 'react';

import { CountData } from '../../types';
import { categories, CategoryInfo, FALLBACK_CATEGORIES } from '../categories';
import { CountryCodes, countryCodes } from '../countries';

interface FilterContext {
    categories: CategoryInfo[];
    countryCodes: CountryCodes;
    categoriesCount?: CountData;
    countryCount?: CountData;
    isCertifiedCount?: number;
    isSingleUseCount?: number;
}

export const FilterContext = React.createContext<FilterContext>({
    categories: FALLBACK_CATEGORIES,
    countryCodes: {},
});

export const useFilterContextValue = () =>
    React.useMemo<FilterContext>(
        () => ({
            categories: categories.read(),
            countryCodes: countryCodes.read(),
        }),
        []
    );

export const useFilterContext = () => React.useContext(FilterContext);
