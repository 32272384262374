interface FilterAction {
    id: string;
    type: 'added' | 'removed';
}

export function countryFilterReducer(
    queryString: string | null,
    action: FilterAction
) {
    const selectedCountries = queryString?.split(',') ?? [];
    switch (action.type) {
        case 'added':
            return [...selectedCountries, action.id].join(',');
        case 'removed':
            return selectedCountries
                .filter(domain => domain !== action.id)
                .join(',');
        default:
            throw new Error('Dispatched illegal country filter action');
    }
}

export default countryFilterReducer;
