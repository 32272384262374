import Axios from 'axios';

import { wrapPromise } from '../../utils';

export interface CategoryInfo {
    id: string;
    subCategories?: Pick<CategoryInfo, 'id'>[];
}

const fetchCategories = async () => {
    const { data } = await Axios.get<{ categories: CategoryInfo[] }>(
        `/categories.json`,
        {
            baseURL: '/',
        }
    );
    return data.categories;
};

export const categories = wrapPromise<CategoryInfo[]>(fetchCategories());

export default categories;
