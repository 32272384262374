import {
    asyncWithLDProvider,
    useFlags,
    useLDClient,
} from 'launchdarkly-react-client-sdk';
import { useCallback } from 'react';

import { Contact } from './types';
import { camelize, isProd } from './utils';

type LaunchDarklyUser = import('launchdarkly-js-client-sdk').LDUser;
type LaunchDarklyFlagSet = import('launchdarkly-js-client-sdk').LDFlagSet;

const clientSideID = isProd
    ? '5ed0c4cfd32a230a5789dd37'
    : '5ed0c4cfd32a230a5789dd36';

const featureToggleUserSelector = (user?: Contact): LaunchDarklyUser =>
    user
        ? {
              key: user.contactId,
              name: user.contactName,
              email: user.emailAddress,
              country: user.countryCode,
          }
        : { anonymous: true };

export const getFeatureToggleProvider = (user?: Contact) =>
    asyncWithLDProvider({
        clientSideID,
        user: featureToggleUserSelector(user),
    });

export const useItendifyToggleContext = () => {
    const client = useLDClient();
    const identify = useCallback(
        (
            user?: Contact,
            callback?: (
                err: Error | null,
                flags: LaunchDarklyFlagSet | null
            ) => void
        ) =>
            client?.identify(
                featureToggleUserSelector(user),
                user?.contactId,
                callback
            ),
        [client]
    );
    return identify;
};

export const useFeatureEnabled = (key: string, fallbackValue = false) => {
    const flags = useFlags();
    return flags[camelize(key)] ?? fallbackValue;
};
