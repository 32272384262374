import './polyfill';
import '@mercell/mercell-ui/dist/index.css';
import './i18n';
import './axios';

import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import SplashLoading from './components/SplashLoading';
import { getContact } from './domain/user/api';
import { AuthContextProvider } from './domain/user/AuthContext';
import { getFeatureToggleProvider } from './FeatureToggleContext';
import * as serviceWorker from './serviceWorker';

(async () => {
    let user;
    try {
        user = await getContact();
    } catch (e) {
        console.error(e);
    }
    const LaunchDarklyProvider = await getFeatureToggleProvider(user);

    ReactDOM.render(
        <React.StrictMode>
            <React.Suspense
                fallback={
                    <SplashLoading>
                        We are assembling the application for you now...
                    </SplashLoading>
                }
            >
                <LaunchDarklyProvider>
                    <AuthContextProvider initialContact={user}>
                        <HelmetProvider>
                            <Router>
                                <App />
                            </Router>
                        </HelmetProvider>
                    </AuthContextProvider>
                </LaunchDarklyProvider>
            </React.Suspense>
        </React.StrictMode>,
        document.getElementById('root')
    );
})();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
