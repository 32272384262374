import * as React from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';

import { useAuthContext } from '../../AuthContext';
import { Rule } from '../../authorization-rules';

export interface AuthorizationProps {
    rules?: Rule[];
}

export const AuthorizedRoute: React.FunctionComponent<RouteProps &
    AuthorizationProps> = ({ component, children, rules, ...rest }) => {
    const { isLoggedIn, contact } = useAuthContext();
    const { pathname } = useLocation();
    const redirect = React.useMemo(() => {
        if (!isLoggedIn) return `/login?redirect=${pathname}`;
        return rules?.find(({ validator }) => !validator(contact))?.redirect;
    }, [isLoggedIn, contact, rules, pathname]);
    return (
        <Route {...rest}>
            {redirect && <Redirect to={redirect} />}
            {!redirect &&
                (children || (component && React.createElement(component)))}
        </Route>
    );
};
