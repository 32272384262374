import qs, { ParsedQuery } from 'query-string';
import { useLocation } from 'react-router-dom';

export const formatTimeString = (dateTime: Date | string) => {
    const timestamp =
        typeof dateTime === 'string' ? new Date(dateTime) : dateTime;
    return `${timestamp.toLocaleDateString(
        'en-GB'
    )} ${timestamp.toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
    })}`;
};

export function useSeachParams() {
    return useLocation().search;
}

export function toUrlParams(object: { [key: string]: any }): string {
    return qs.stringify(object, { skipNull: true, skipEmptyString: true });
}

export function fromUrlParams<
    T extends ParsedQuery<string | boolean> = ParsedQuery
>(search: string) {
    return qs.parse(search) as T;
}

export function getAppDomain() {
    return window.location.hostname.split('.').pop() ?? '';
}

export const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.0/8 are considered localhost for IPv4.
        window.location.hostname.match(
            /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
);

export const camelize = (string: string) =>
    string
        .split('-')
        .map((item, index) =>
            index
                ? item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()
                : item
        )
        .join('');

export const isQA = Boolean(
    window.location.hostname
        .split('.')
        .some(urlPart => urlPart.toUpperCase() === 'QA')
);

export const isProd = Boolean(!isQA && !isLocalhost);

// from https://reactjs.org/docs/concurrent-mode-suspense.html
export function wrapPromise<T>(promise: Promise<T>) {
    let status = 'pending';
    let result: T | Error;
    const suspender = promise.then(
        r => {
            status = 'success';
            result = r;
        },
        e => {
            status = 'error';
            result = e;
        }
    );
    return {
        read() {
            if (status === 'pending') {
                throw suspender;
            } else if (status === 'error') {
                throw result;
            }
            return result as T;
        },
    };
}

export default { useSeachParams };
