import * as React from 'react';
import { Helmet } from 'react-helmet-async';

interface MetaTagsProps {
    title: string;
    imageUrl?: string;
    url?: string;
    description?: string;
}

const MetaTags: React.FunctionComponent<MetaTagsProps> = ({
    title,
    imageUrl,
    description = '- Cause we want to contribute B2B during the Corona crisis',
    url = 'https://findhinanden.dk',
}) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="title" content={title} />
            {/* Open graph */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content={url} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            {imageUrl && <meta property="og:image" content={imageUrl} />}
            {/* twitter */}
            <meta property="twitter:card" content={description} />
            <meta property="twitter:url" content={url} />
            <meta property="twitter:title" content={title} />
            <meta property="twitter:description" content={description} />
            {imageUrl && <meta property="twitter:image" content={imageUrl} />}
        </Helmet>
    );
};

export default MetaTags;
