import {
    CarbonIconType,
    WatsonHealthICa_2D24 as FallbackIcon,
} from '@mercell/mercell-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useFilterContext } from '../filters/FilterContext';
import { CategoryIconMap } from './categories';
import { getTranslationKey } from './utils';

interface CategoryData {
    id: string;
    count: number | null;
    Icon: CarbonIconType;
    label: string;
    subCategories?: CategoryData[];
}

export interface Category
    extends Required<Omit<CategoryData, 'subCategories'>> {
    to?: string;
    subCategories: Omit<Category, 'subCategories'>[];
}

export const useCategories = (): Category[] => {
    const { categories, categoriesCount } = useFilterContext();
    const { t } = useTranslation();
    return useMemo(
        () =>
            categories.map(({ id, subCategories }) => {
                const getCategory = (categoryId: string) => ({
                    id: categoryId,
                    Icon: CategoryIconMap[categoryId] ?? FallbackIcon,
                    label: t(getTranslationKey(categoryId)),
                    count: categoriesCount?.[categoryId] ?? null,
                });
                const baseCategory = getCategory(id);
                const sub = subCategories?.map(({ id: subId }) =>
                    getCategory(subId)
                );
                return { ...baseCategory, subCategories: sub ?? [] };
            }),
        [categories, categoriesCount, t]
    );
};

export default useCategories;
