import cx from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { Category as CategoryType } from '../../domain/categories/hooks';
import Category from '../Category';

interface CategoryBarProps {
    className?: string;
    role?: 'navigation';
    children: (category: CategoryType) => React.ReactNode;
    categories: CategoryType[];
}

interface CategoryRadioSelectProps
    extends Pick<CategoryBarProps, 'className' | 'categories'> {
    name: string;
}

export const CategoryRadioSelect: React.FC<CategoryRadioSelectProps> = ({
    name,
    ...props
}) => (
    <CategoryBar {...props}>
        {({ id, ...rest }) => (
            <>
                <label htmlFor={id}>
                    <input
                        name={name}
                        id={id}
                        type="radio"
                        className="opacity-0"
                    />
                    <Category id={id} {...rest} />
                </label>
            </>
        )}
    </CategoryBar>
);

interface CategoryCheckboxSelectProps
    extends Pick<CategoryBarProps, 'className' | 'categories'> {
    initialValues?: { [key: string]: boolean };
    onChange: (fields: { [key: string]: boolean }) => void;
}

export const CategoryCheckboxSelect: React.FC<CategoryCheckboxSelectProps> = ({
    initialValues,
    onChange,
    ...props
}) => {
    const [fields, setFields] = React.useState<{ [key: string]: boolean }>(
        initialValues ?? {}
    );
    return (
        <CategoryBar {...props}>
            {({ id, ...rest }) => {
                if (!id) return null;
                return (
                    <>
                        <input
                            name={id}
                            id={id}
                            type="checkbox"
                            className="opacity-0"
                            onChange={() => {
                                const categories = {
                                    ...fields,
                                    [id]: !fields[id],
                                };
                                if (id) setFields(categories);
                                onChange(categories);
                            }}
                        />
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label
                            htmlFor={id}
                            className="clear-pseudo"
                            style={{ paddingLeft: 0 }}
                        >
                            <Category
                                id={id}
                                {...rest}
                                className={
                                    fields[id] ? 'bg-primary text-white' : ''
                                }
                            />
                        </label>
                    </>
                );
            }}
        </CategoryBar>
    );
};

export const CategoryNavBar: React.FC<Pick<CategoryBarProps, 'className'> & {
    categories: Required<CategoryType>[];
}> = props => (
    <CategoryBar role="navigation" {...props}>
        {({ to, ...rest }) => (
            <>
                <Link to={to ?? '/'} className="w-full text-black">
                    <Category {...rest} />
                </Link>
            </>
        )}
    </CategoryBar>
);

const CategoryBar: React.FunctionComponent<CategoryBarProps> = ({
    children,
    role,
    categories,
    className,
}) => {
    return (
        <div role={role} className={cx('mt-8 sm:rounded w-full', className)}>
            <ul className="flex flex-col sm:flex-row sm:justify-between">
                {categories.map(category => {
                    return (
                        <li
                            key={category.id}
                            className="border-b sm:border-r border-grey-medium last:border-r-0 last:border-b w-full"
                        >
                            {children(category)}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default CategoryBar;
