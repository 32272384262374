import './styles.css';

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import AdSearch from '../../components/AdSearch';
import { CategoryNavBar } from '../../components/CategoryBar';
import MetaTags from '../../components/MetaTags';
import { useCategories } from '../../domain/categories/hooks';
import { useAuthContext } from '../../domain/user/AuthContext';
import { toUrlParams } from '../../utils';

const Home: React.FunctionComponent<{}> = () => {
    const { t } = useTranslation();
    const { isLoggedIn } = useAuthContext();

    const categories = useCategories()
        .slice(0, 6)
        .map(category => ({
            ...category,
            to: `/browse?categories=${category.id}`,
        }));

    const { push } = useHistory();
    const onSearch = React.useCallback(
        selection => {
            const query = toUrlParams({ search: selection });
            push({ pathname: '/browse', search: query });
        },
        [push]
    );
    const appName = window.location.origin.match(/agores/gi)
        ? 'Agores'
        : 'Findhinanden';
    return (
        <>
            <MetaTags title={appName} />
            <div className="background w-full h-full py-8 flex-col flex items-center">
                <div className="flex p-4 sm:p-16 sm:pb-4 flex-col home-content sm:rounded w-full">
                    <h1 className="text-center mb-4">{appName}</h1>
                    <h2 className="text-center text-xl">
                        {t('pages.home.subtitle')}
                    </h2>

                    <small className="text-center mt-3 text-lg">
                        {t('pages.home.quote')}
                    </small>

                    <div className="sm:w-2/3 sm:self-center">
                        <AdSearch
                            value=""
                            placeholder={t('pages.home.placeholder')}
                            onChange={onSearch}
                        />
                    </div>

                    <Link to="/browse" className="text-center mt-4">
                        {t('pages.home.all-ads')}
                    </Link>
                    {!isLoggedIn && (
                        <div className="flex justify-center mt-4">
                            {t('pages.home.supplier')}
                            <Link to="/login" className="mx-2">
                                {t('log-in')}
                            </Link>
                        </div>
                    )}
                    <a
                        href={t('support.link')}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-center mt-4"
                    >
                        {t('support.label')}
                    </a>
                </div>
                <CategoryNavBar
                    className="home-content"
                    categories={[...categories]}
                />
            </div>
        </>
    );
};

export default Home;
