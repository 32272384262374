import './styles.css';

import { Button, DropdownMenu, UserAvatar24 } from '@mercell/mercell-ui';
import cx from 'classnames';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { useAuthContext } from '../../domain/user/AuthContext';

interface ProfileDropdownProps {
    className?: string;
}

const LoggedInLinks: React.FunctionComponent<ProfileDropdownProps> = ({
    className,
}) => {
    const { t } = useTranslation();
    const { logout, contact } = useAuthContext();
    const { pathname } = useLocation();
    const [showProfileMenu, setShowProfileMenu] = React.useState<boolean>(
        false
    );
    const isActive = React.useMemo(
        () =>
            ['/account'].some(
                path =>
                    pathname.match(path) &&
                    pathname.split('/').pop() === contact?.contactId
            ),
        [pathname, contact]
    );
    return (
        <div className={cx('relative h-full', className)}>
            <button
                onClick={() => setShowProfileMenu(true)}
                type="button"
                className={cx('nav-link w-full title-format', {
                    'active active-underline': showProfileMenu || isActive,
                })}
            >
                <UserAvatar24 className="fill-current" />
                <span className="title-format">
                    {contact?.contactName ?? contact?.emailAddress ?? 'User'}
                </span>
            </button>
            {showProfileMenu && (
                <DropdownMenu
                    id="profile-dropdown"
                    onClose={() => setShowProfileMenu(false)}
                    className="nav-dropdown"
                >
                    <li>
                        <Link
                            to="/user"
                            onClick={() => setShowProfileMenu(false)}
                        >
                            {t('pages.user-info.default.title')}
                        </Link>
                    </li>
                    <li>
                        <Link
                            to={`/account/${contact?.contactId}`}
                            onClick={() => setShowProfileMenu(false)}
                        >
                            {t('nav.my-ads')}
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/language"
                            onClick={() => setShowProfileMenu(false)}
                        >
                            {t('pages.language.title')}
                        </Link>
                    </li>
                    <li>
                        <a
                            href={t('support.link')}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {t('nav.support')}
                        </a>
                    </li>
                    <li>
                        <Button scheme="ghost" onClick={logout}>
                            {t('nav.log-out')}
                        </Button>
                    </li>
                    <li className="sm:hidden">
                        <Button
                            scheme="ghost"
                            onClick={() => setShowProfileMenu(false)}
                        >
                            {t('nav.close-menu')}
                        </Button>
                    </li>
                </DropdownMenu>
            )}
        </div>
    );
};

export default LoggedInLinks;
