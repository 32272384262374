/* eslint-env browser */
import axios from 'axios';
import * as React from 'react';

import { useItendifyToggleContext } from '../../FeatureToggleContext';
import { Contact } from '../../types';

interface AuthContext {
    isLoggedIn: boolean;
    contact?: Contact;
    setLoggedIn: (contact: Contact) => void;
    logout: () => Promise<void>;
}

const AuthContext = React.createContext<AuthContext>({
    isLoggedIn: false,
    setLoggedIn: (contact: Contact) => undefined,
    logout: async () => undefined,
});

export const AuthContextProvider: React.FunctionComponent<{
    initialContact?: Contact;
}> = ({ children, initialContact }) => {
    const identifyFeatureToggleUser = useItendifyToggleContext();
    const logout = React.useCallback(async () => {
        try {
            await axios.get('/user/logout');
            window.location.reload();
        } catch (e) {
            console.error(e);
        }
    }, []);

    const [context, setContext] = React.useState<
        Omit<AuthContext, 'setLoggedIn'>
    >({
        isLoggedIn: !!initialContact,
        logout,
        contact: initialContact,
    });

    const setLoggedIn = React.useCallback(
        (contact: Contact) => {
            identifyFeatureToggleUser(contact);
            setContext({ ...context, isLoggedIn: true, contact });
        },
        [context, identifyFeatureToggleUser]
    );

    return (
        <AuthContext.Provider
            value={{
                ...context,
                setLoggedIn,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuthContext = () => React.useContext(AuthContext);

export const useAuthorization = (emailAddress?: string) => {
    const { contact } = useAuthContext();

    const isOwner = React.useMemo(
        () => emailAddress && contact?.emailAddress === emailAddress,
        [contact, emailAddress]
    );

    return {
        isOwner,
    };
};

export default AuthContext;
