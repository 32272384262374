import { getParentCategory, includeParentCategories } from './utils';

export interface FilterAction {
    id: string;
    type: 'added' | 'removed';
}

export function categoryFilterReducer(
    queryString: string | null,
    action: FilterAction
) {
    const state = queryString?.split(',') ?? [];
    switch (action.type) {
        case 'added':
            return [...state, action.id].join(',');
        case 'removed':
            return state
                .filter(
                    category =>
                        category !== action.id &&
                        action.id !== getParentCategory(category)
                )
                .join(',');
        default:
            throw new Error('Dispatched illegal category filter action');
    }
}

export function categorySelectionReducer(
    state: string[],
    action: FilterAction
) {
    switch (action.type) {
        case 'added':
            return includeParentCategories([...state, action.id]);
        case 'removed':
            return includeParentCategories(
                state.filter(category => category !== action.id)
            );
        default:
            throw new Error('Dispatched illegal category selection action');
    }
}

export default categoryFilterReducer;
