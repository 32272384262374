import { Alert, Button, Input } from '@mercell/mercell-ui';
import Axios from 'axios';
import { TFunction } from 'i18next';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { fromUrlParams, useSeachParams } from '../../utils';

const schema = (t: TFunction) =>
    yup.object().shape({
        password: yup.string().required(t('fields.password.errors.required')),
        passwordRetyped: yup
            .string()
            .required(t('fields.password-confirm.errors.required'))
            .oneOf(
                [yup.ref('password'), null],
                t('fields.password-confirm.errors.match')
            ),
        token: yup.string(),
    });

type PasswordChangePayload = yup.InferType<ReturnType<typeof schema>>;

const ForgottenPassword: React.FunctionComponent<{}> = () => {
    const { t } = useTranslation();
    const searchParams = useSeachParams();
    const { token } = fromUrlParams<{ token: string }>(searchParams);
    const { register, handleSubmit, errors, triggerValidation } = useForm<
        PasswordChangePayload
    >({
        validationSchema: schema(t),
        defaultValues: {
            token,
            password: '',
            passwordRetyped: '',
        },
    });
    const { replace } = useHistory();
    const [error, setError] = React.useState<Error>();
    return (
        <>
            <h1>{t('pages.change-password.title')}</h1>
            {error && <Alert scheme="danger">{t('server.error')}</Alert>}
            <form
                onSubmit={handleSubmit(async payload => {
                    try {
                        await Axios.post('/user/password-change', payload);
                        replace(
                            `/login?statusMsg=${t(
                                'pages.change-password.form.success'
                            )}&statusMsgType=success-light`
                        );
                    } catch (e) {
                        setError(e);
                    }
                })}
            >
                <input hidden name="token" ref={register} />
                <Input
                    errorMessage={errors.password?.message as string}
                    id="password"
                    type="password"
                    label={t('fields.password.label')}
                    name="password"
                    className="mb-4 w-64"
                    ref={register}
                />
                <Input
                    errorMessage={errors.passwordRetyped?.message as string}
                    id="password-confirm"
                    type="password"
                    onBlur={() => triggerValidation('passwordRetyped')}
                    label={t('fields.password-confirm.label')}
                    name="passwordRetyped"
                    className="mb-4 w-64"
                    ref={register}
                />
                <Button type="submit" scheme="primary">
                    {t('pages.change-password.form.submit')}
                </Button>
            </form>
        </>
    );
};

export default ForgottenPassword;
