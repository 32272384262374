import { Information24, Toast, useShowTimer } from '@mercell/mercell-ui';
import * as React from 'react';

import { fromUrlParams, toUrlParams, useSeachParams } from '../../utils';

interface StatusMessage {
    statusMsg: string;
    statusMsgType?: 'grey-light' | 'success-light' | 'black';
    timeout?: number;
}

export const getStatusMessageSearchUri = (message: StatusMessage) =>
    toUrlParams(message);

const ApplicationStatusMessage: React.FunctionComponent<{}> = () => {
    const search = useSeachParams();
    const { timeout, statusMsg } = fromUrlParams<{
        timeout?: string;
        statusMsg?: string;
    }>(search);
    const { triggerShow, show } = useShowTimer();

    React.useEffect(() => {
        if (statusMsg) triggerShow(timeout);
    }, [timeout, statusMsg, triggerShow]);
    if (!show) return null;
    return (
        <Toast isInteractive={false} className="w-fit-content">
            <Information24 className="fill-current stroke-current mr-2" />
            <span className="title-format">{decodeURI(statusMsg ?? '')}</span>
        </Toast>
    );
};

export default ApplicationStatusMessage;
