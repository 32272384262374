import { Contact } from '../../types';

export type AuthorizationValidator = (contact?: Contact) => boolean;

export interface Rule {
    redirect: string;
    validator: (contact?: Contact) => boolean;
}

export const hasUserInfo: AuthorizationValidator = contact =>
    !!contact?.organizationNumber;
