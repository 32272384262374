import { Alert, Button, Input } from '@mercell/mercell-ui';
import Axios from 'axios';
import { TFunction } from 'i18next';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';

const schema = (t: TFunction) =>
    yup.object().shape({
        email: yup.string().required(t('fields.email.errors.required')),
    });

type PasswordResetPayload = yup.InferType<ReturnType<typeof schema>>;

const ForgottenPassword: React.FunctionComponent<{}> = () => {
    const { t } = useTranslation();

    const { register, handleSubmit, errors, reset } = useForm<
        PasswordResetPayload
    >({
        validationSchema: schema(t),
    });
    const [success, setSuccess] = React.useState<string>('');
    const [error, setError] = React.useState<Error>();
    return (
        <>
            <h1>{t('pages.forgotten-password.title')}</h1>
            {success && (
                <Alert scheme="success" className="my-4">
                    <Trans i18nKey="pages.forgotten-password.form.success">
                        We&apos;ve sent a message to:
                        <a href="mailto:">{{ success }}</a>, if we wound a user
                        associated with the address.
                    </Trans>
                </Alert>
            )}
            {error && <Alert scheme="danger">{t('server.error')}</Alert>}
            <form
                onSubmit={handleSubmit(async payload => {
                    try {
                        await Axios.post('/user/password-reset', payload);
                        setSuccess(payload.email);
                        reset();
                    } catch (e) {
                        setError(e);
                    }
                })}
            >
                <Input
                    label={t('fields.email.label')}
                    name="email"
                    id="email"
                    type="email"
                    className="my-4 w-64"
                    ref={register}
                    errorMessage={errors.email?.message as string}
                />
                <Button type="submit" scheme="primary">
                    {t('pages.forgotten-password.form.submit')}
                </Button>
            </form>
        </>
    );
};

export default ForgottenPassword;
