import {
    CarbonIconType,
    Development24,
    Group24,
    ManageProtection24,
    Movement24,
    SprayPaint24,
    WatsonHealthCobbAngle24,
} from '@mercell/mercell-ui';

import { CategoryInfo } from './api';

export const CategoryIconMap: { [id: string]: CarbonIconType } = {
    protection: ManageProtection24,
    cleaning: SprayPaint24,
    inventory: Development24,
    services: Movement24,
    health: Group24,
    other: WatsonHealthCobbAngle24,
};

export const FALLBACK_CATEGORIES: CategoryInfo[] = [
    {
        id: 'protection',
        subCategories: [
            { id: 'protection_sanitizer' },
            { id: 'protection_mask' },
            { id: 'protection_face-shielding' },
            { id: 'protection_gloves' },
            { id: 'protection_clothing' },
        ],
    },
    {
        id: 'cleaning',
        subCategories: [
            { id: 'cleaning_fluid-cleaning' },
            { id: 'cleaning_paper' },
            { id: 'cleaning_other-cleaning' },
        ],
    },
    {
        id: 'inventory',
        subCategories: [
            { id: 'inventory_indoors' },
            { id: 'inventory_outdoors' },
            { id: 'inventory_indoors-other' },
            { id: 'inventory_outdoors-other' },
        ],
    },
    {
        id: 'services',
        subCategories: [
            { id: 'services_security' },
            { id: 'services_transport' },
            { id: 'services_other-service' },
        ],
    },
    {
        id: 'health',
        subCategories: [
            { id: 'health_physical-health' },
            { id: 'health_physical-rehabilitation' },
            { id: 'health_mental-health' },
            { id: 'health_mental-rehabilitation' },
        ],
    },
    {
        id: 'other',
        subCategories: [
            { id: 'other_electronics' },
            { id: 'other_signage' },
            { id: 'other_body-cleaning' },
            { id: 'other_clothing' },
            { id: 'other_other' },
        ],
    },
];
