import Axios from 'axios';

import { Contact } from '../../types';

export const getContact = async () => {
    const { data } = await Axios.get<Contact>('/user');
    return data;
};

export default getContact();
