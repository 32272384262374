import { Button } from '@mercell/mercell-ui';
import cx from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';

const CookieWarning: React.FunctionComponent<{}> = () => {
    const cookieAccepted = localStorage
        ? (JSON.parse(
              window.localStorage.getItem('accepted-cookies') ?? 'false'
          ) as boolean)
        : false;

    const [accepted, setAccepted] = React.useState<boolean>(cookieAccepted);

    function setCookieAccepted() {
        if (window.localStorage) {
            window.localStorage.setItem(
                'accepted-cookies',
                JSON.stringify(true)
            );
            setAccepted(true);
        }
    }

    if (accepted) return null;
    return (
        <div
            className={cx(
                'cookieAlert fixed top-0 left-0 w-screen bg-grey-dark text-white p-4 text-sm text-white text-center z-50'
            )}
        >
            Vi bruger cookies for at forbedre din oplevelse og vurdere brugen af
            de enkelte elementer på findhinanden.dk. Ved at klikke videre
            accepterer du vores brug af cookies.
            <Button
                scheme="secondary"
                onClick={setCookieAccepted}
                className={cx('bg-primary-hover px-2 py-1 sm:mx-4')}
            >
                OK
            </Button>
            <Link
                to="/privacy"
                className={cx(
                    'text-grey-light hover:text-grey-light hover:no-underline'
                )}
            >
                Læs mere
            </Link>
        </div>
    );
};

export default CookieWarning;
