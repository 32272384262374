import {
    AutoComplete,
    Fieldset,
    Search32,
    useAutoCompleteSearch,
} from '@mercell/mercell-ui';
import { AutoCompleteProps } from '@mercell/mercell-ui/dist/types/components/AutoComplete';
import Axios from 'axios';
import cx from 'classnames';
import * as React from 'react';

import { AdsResponse } from '../../types';

interface AdSearchProps {
    onChange: (search: string) => void;
    value: string;
}

const AdSearch: React.FunctionComponent<AdSearchProps &
    Partial<Omit<AutoCompleteProps, 'onChange'>>> = ({
    className,
    onChange,
    value,
    ...props
}) => {
    const [search, setSearch] = React.useState<string>(value);

    const autoComplete = useAutoCompleteSearch({
        onSearch: async searchValue => {
            if (!searchValue) return [];
            try {
                const adResponse = await Axios.get<AdsResponse>(
                    `/search?search=${searchValue ?? ''}`
                );
                return adResponse.data.advertisements.map(({ title }) => title);
            } catch (error) {
                return [];
            }
        },
        search,
    });

    const inputRef = React.useRef<HTMLInputElement>(null);
    const onSearch = React.useCallback(
        selection => {
            if (inputRef.current && selection) inputRef.current.blur();
            onChange(selection);
        },
        [onChange]
    );

    return (
        <Fieldset
            id="ad-search"
            label=""
            className={cx('flex items-center', className)}
        >
            <AutoComplete
                {...autoComplete}
                id="ad-search-field"
                className="w-full my-4 pr-10"
                canAdd
                ref={inputRef}
                value={search}
                onChange={evt => {
                    const { value: inputValue } = evt.target;
                    setSearch(inputValue);
                    if (!inputValue) onSearch('');
                }}
                onSelect={onSearch}
                {...props}
            />
            <Search32 className="z-10 h-full my-4" />
        </Fieldset>
    );
};

export default AdSearch;
