import Axios from 'axios';

import { wrapPromise } from '../../utils';

export interface CountryCodes {
    [value: number]: string;
}

export interface CountryData {
    code: string;
    dialCode: string;
    name: string;
}

export interface CountryConfig {
    [domain: string]: CountryData;
}

const fetchCountryCodes = async () => {
    const { data } = await Axios.get<CountryCodes>(
        '/search/SupportedCountries'
    );
    return data;
};

const fetchCountryList = async () => {
    const { data } = await Axios.get<CountryConfig>('/country-codes.json', {
        baseURL: '/',
    });
    return data;
};

export const countryCodes = wrapPromise<CountryCodes>(fetchCountryCodes());
export const getCountryList = wrapPromise<CountryConfig>(fetchCountryList());
