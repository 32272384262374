import { WatsonHealthICa_2D24 as FallbackIcon } from '@mercell/mercell-ui';
import cx from 'classnames';
import * as React from 'react';

import { Category as CategoryType } from '../../domain/categories/hooks';

export interface CategoryProps extends CategoryType {
    className?: string;
}

const Category: React.FunctionComponent<CategoryProps> = ({
    Icon = FallbackIcon,
    label,
    className,
}) => {
    return (
        <div
            className={cx(
                'h-full xl:w-32 lg:w-32 md:w-32 flex flex-col items-center p-4 text-center justify-center',
                className
            )}
        >
            <Icon className="fill-current" />
            <span>{label}</span>
        </div>
    );
};

export default Category;
